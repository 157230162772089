import { useState } from "react";
import React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableFooter from "@mui/material/TableFooter";
import TableSortLabel from "@mui/material/TableSortLabel";
import { styled } from "@mui/system";
import { Box, TablePagination } from "@mui/material";
import ExtraCell from "./ExtraComponents/ExtraCell";
// import companyData from "../data/data.json";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: "#EDF4FB",
  color: "black",
  // "&:hover": {
  //   color: "white",
  // },
  "& .MuiTableSortLabel-root": {
    color: "black !important",
    "&:hover": {
      // color: "white !important",
    },
    "&.Mui-active": {
      color: "blue !important",
      // backgroundColor: "rgba(245, 245, 245, 1)",
    },
    "& .MuiTableSortLabel-icon": {
      // color: "white !important",
      display: "none",
    },
  },
}));

export default function CompanyTable({ companyData, queryString }) {
  // console.log({ companyData });
  const data = companyData.company_data;
  // console.log({ data });
  const totalData = companyData.total;
  const currencies = companyData.currencies;
  const length = currencies.length;
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");

  const currentDate = new Date();
  const year = currentDate.getFullYear();

  function getNext12Months() {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const today = new Date();
    const currentMonth = today.getMonth();
    const currentYear = today.getFullYear();

    let next12Months = [];
    for (let i = 0; i < 12; i++) {
      const monthIndex = (currentMonth + i) % 12;
      const yearOffset = Math.floor((currentMonth + i) / 12);
      const year = currentYear + yearOffset;
      next12Months.push({
        month: months[monthIndex],
        year: year,
      });
    }

    return next12Months;
  }

  const months = getNext12Months();
  // console.log({ months });

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    // console.log({ sortby: property });
    setOrderBy(property);
  };

  const sortedData = () => {
    const entries = Object.entries(data);
    if (
      orderBy &&
      (orderBy === "Company name" ||
        orderBy === "Plans" ||
        orderBy === "Currency")
    ) {
      entries.sort((a, b) => {
        let aValue, bValue;
        switch (orderBy) {
          case "Company name":
            aValue = a[0].split(" ---- ")[0];
            bValue = b[0].split(" ---- ")[0];
            break;
          case "Plans":
            aValue = a[0].split(" ---- ")[1];
            bValue = b[0].split(" ---- ")[1];
            break;
          case "Currency":
            aValue = a[0].split(" ---- ")[2];
            bValue = b[0].split(" ---- ")[2];
            break;
          default:
            aValue = a[1][orderBy];
            bValue = b[1][orderBy];
        }
        if (aValue < bValue) {
          return order === "asc" ? -1 : 1;
        }
        if (aValue > bValue) {
          return order === "asc" ? 1 : -1;
        }
        return 0;
      });
    } else if (
      orderBy &&
      (orderBy.includes("Jun") ||
        orderBy.includes("Jul") ||
        orderBy.includes("Aug") ||
        orderBy.includes("Sep") ||
        orderBy.includes("Oct") ||
        orderBy.includes("Nov") ||
        orderBy.includes("Dec") ||
        orderBy.includes("Jan") ||
        orderBy.includes("Feb") ||
        orderBy.includes("Mar") ||
        orderBy.includes("Apr") ||
        orderBy.includes("May"))
    ) {
      entries.sort((a, b) => {
        let aValue, bValue;
        switch (orderBy) {
          case "January":
            aValue = a[1]?.January;
            bValue = b[1]?.January;
            break;
          case "February":
            aValue = a[1]?.February;
            bValue = b[1]?.February;
            break;
          case "March":
            aValue = a[1]?.March;
            bValue = b[1]?.March;
            break;
          case "April":
            aValue = a[1]?.April;
            bValue = b[1]?.April;
            break;
          case "May":
            aValue = a[1]?.May;
            bValue = b[1]?.May;
            break;
          case "June":
            aValue = a[1]?.June;
            bValue = b[1]?.June;
            break;
          case "July":
            aValue = a[1]?.July;
            bValue = b[1]?.July;
            break;
          case "August":
            aValue = a[1]?.August;
            bValue = b[1]?.August;
            break;
          case "September":
            aValue = a[1]?.September;
            bValue = b[1]?.September;
            break;
          case "October":
            aValue = a[1]?.October;
            bValue = b[1]?.October;
            break;
          case "November":
            aValue = a[1]?.November;
            bValue = b[1]?.November;
            break;
          case "December":
            aValue = a[1]?.December;
            bValue = b[1]?.December;
            break;
          default:
            aValue = a[1][orderBy];
            bValue = b[1][orderBy];
        }
        if (aValue !== null && bValue !== null) {
          return order === "asc" ? aValue - bValue : bValue - aValue;
        }
        return 0;
      });
    }
    return entries;
  };

  // pagination codes
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const rowsToDisplay = sortedData()
    .filter((record) => {
      const parts = record[0].split(" ---- ");
      const companyName = parts[0];
      const service = parts[1];
      return (
        companyName?.toUpperCase()?.includes(queryString.toUpperCase()) ||
        service?.toUpperCase()?.includes(queryString.toUpperCase())
      );
    })
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const emptyRows = rowsPerPage - rowsToDisplay.length;

  // based on totalData, generate all mmrs
  let comissionMmr = 0;
  let extensionMrr = 0;
  let totalMrr = 0;
  let USDMrr = 0;
  let EURMrr = 0;
  let AUDMrr = 0;
  let CADMrr = 0;
  let GBPMrr = 0;
  let INRMrr = 0;

  Object.values(totalData)?.forEach((valueMap) => {
    comissionMmr = comissionMmr + valueMap?.zoho;
    extensionMrr = extensionMrr + valueMap?.extensions;
    totalMrr = totalMrr + valueMap?.total_in_usd;
    USDMrr = USDMrr + valueMap?.USD;
    EURMrr = EURMrr + valueMap?.EUR;
    AUDMrr = AUDMrr + valueMap?.AUD;
    CADMrr = CADMrr + valueMap?.CAD;
    GBPMrr = GBPMrr + valueMap?.GBP;
    INRMrr = INRMrr + (typeof valueMap?.INR === "number" ? valueMap.INR : 0);
  });

  comissionMmr = (comissionMmr / 12).toFixed(2);
  extensionMrr = (extensionMrr / 12).toFixed(2);
  totalMrr = (totalMrr / 12).toFixed(2);
  let currencyMrrs = {
    USD: (USDMrr / 12).toFixed(2),
    EUR: (EURMrr / 12).toFixed(2),
    AUD: (AUDMrr / 12).toFixed(2),
    CAD: (CADMrr / 12).toFixed(2),
    GBP: (GBPMrr / 12).toFixed(2),
    INR: (INRMrr / 12).toFixed(2),
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer>
        <Table
          stickyHeader
          aria-label="sticky table"
          sx={{ borderCollapse: "separate", borderSpacing: 0, width: "100%" }}
          size="small"
        >
          <TableHead sx={{ height: 50 }}>
            <TableRow sx={{ width: "100%" }}>
              <StyledTableCell
                align={"left"}
                sx={{
                  width: "30%",
                  maxWidth: "30%",
                  minWidth: "30%",
                  border: "1px solid #ddd",
                  position: "sticky",
                  top: 0,
                }}
              >
                <TableSortLabel
                  active={orderBy === "Company name"}
                  direction={orderBy === "Company name" ? order : "asc"}
                  onClick={() => handleRequestSort("Company name")}
                >
                  Company Name
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell
                sx={{
                  width: "19%",
                  maxWidth: "19%",
                  minWidth: "19%",
                  border: "1px solid #ddd",
                  position: "sticky",
                  top: 0,
                }}
              >
                <TableSortLabel
                  active={orderBy === "Plans"}
                  direction={orderBy === "Plans" ? order : "asc"}
                  onClick={() => handleRequestSort("Plans")}
                >
                  Plans
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell
                sx={{
                  width: "3%",
                  maxWidth: "3%",
                  minWidth: "3%",
                  border: "1px solid #ddd",
                  position: "sticky",
                  top: 0,
                }}
              >
                <TableSortLabel
                  active={orderBy === "Currency"}
                  direction={orderBy === "Currency" ? order : "asc"}
                  onClick={() => handleRequestSort("Currency")}
                >
                  $$
                </TableSortLabel>
              </StyledTableCell>

              {months.map((month, index) => (
                <StyledTableCell
                  key={index}
                  sx={{
                    border: "1px solid #ddd",
                    position: "sticky",
                    top: 0,
                    width: "4%",
                    minWidth: "4%",
                    maxWidth: "4%",
                  }}
                >
                  <TableSortLabel
                    active={orderBy === `${month.month}`}
                    direction={orderBy === `${month.month}` ? order : "asc"}
                    onClick={() => handleRequestSort(`${month.month}`)}
                  >
                    {month.month.slice(0, 3)}-
                    {month.year.toLocaleString().slice(3)}
                  </TableSortLabel>
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              minHeight: "350px",
              height: "350px",
            }}
          >
            {rowsToDisplay.map(([entry, details], index) => {
              const parts = entry.split(" ---- ");
              const companyName = parts[0];
              const service = parts[1];
              const currency = parts[2];
              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={index}
                  sx={{ width: "100%", maxHeight: "100px" }}
                >
                  <TableCell
                    align="left"
                    sx={{
                      border: "1px solid #ddd",
                      width: "30%",
                      maxWidth: "30%",
                      minWidth: "30%",
                    }}
                  >
                    <Box
                      sx={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        width: "100%",
                        display: "inline-block",
                      }}
                    >
                      {companyName}
                    </Box>
                  </TableCell>

                  <TableCell
                    align="left"
                    sx={{
                      border: "1px solid #ddd",
                      width: "19%",
                      maxWidth: "19%",
                      minWidth: "19%",
                    }}
                  >
                    <Box
                      sx={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        width: "100%",
                        display: "inline-block",
                      }}
                    >
                      {service}
                    </Box>
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      border: "1px solid #ddd",
                      width: "3%",
                      maxWidth: "3%",
                      minWidth: "3%",
                    }}
                  >
                    {currency}
                  </TableCell>
                  {months.map((month, index) => {
                    const rawAmount =
                      details?.[month.month]?.toFixed(2) || "0.00";
                    const parsedAmount = parseFloat(rawAmount);
                    const amount =
                      parsedAmount % 1 === 0
                        ? parsedAmount.toFixed(2)
                        : parsedAmount.toString();
                    return (
                      <TableCell
                        key={index}
                        sx={{
                          border: "1px solid #ddd",
                          textAlign: "right",
                          width: "4%",
                          maxWidth: "4%",
                          minWidth: "4%",
                          padding: "3px 6px",
                        }}
                      >
                        {amount || ""}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
            {Array.from({ length: emptyRows }, (_, index) => (
              <TableRow key={`empty-row-${index}`} sx={{ width: "100%" }}>
                <ExtraCell width={30} />
                <ExtraCell width={19} />
                <ExtraCell width={3} />
                <ExtraCell width={4} />
                <ExtraCell width={4} />
                <ExtraCell width={4} />
                <ExtraCell width={4} />
                <ExtraCell width={4} />
                <ExtraCell width={4} />
                <ExtraCell width={4} />
                <ExtraCell width={4} />
                <ExtraCell width={4} />
                <ExtraCell width={4} />
                <ExtraCell width={4} />
                <ExtraCell width={4} />
              </TableRow>
            ))}
          </TableBody>

          <TableFooter sx={{ position: "sticky", bottom: 0 }}>
            <TableRow>
              {/* <TableCell
                sx={{
                  backgroundColor: "#FFF8E1",
                  color: "black",
                  border: "1px solid #ddd",
                  borderCollapse: "collapse",
                }}
                rowSpan={currencies.length + 3}
              >
                Total
              </TableCell> */}
              <TableCell
                sx={{
                  backgroundColor: "#FFF8E1",
                  color: "black",
                  border: "1px solid #ddd",
                  borderCollapse: "collapse",
                }}
                align="right"
                colSpan={3}
              >
                Commission (MRR {comissionMmr})
              </TableCell>
              {months.map((month, index) => (
                <TableCell
                  key={index}
                  sx={{
                    backgroundColor: "#FFF8E1",
                    color: "black",
                    border: "1px solid #ddd",
                    borderCollapse: "collapse",
                    padding: "3px 6px",
                    textAlign: "right",
                  }}
                >
                  {parseInt(totalData?.[month.month]?.["zoho"] || 0)?.toFixed(
                    2
                  )}
                </TableCell>
              ))}
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  backgroundColor: "#FFF8E1",
                  color: "black",
                  border: "1px solid #ddd",
                  borderCollapse: "collapse",
                }}
                align="right"
                colSpan={3}
              >
                Extension (MRR {extensionMrr})
              </TableCell>
              {months.map((month, index) => (
                <TableCell
                  key={index}
                  sx={{
                    backgroundColor: "#FFF8E1",
                    color: "black",
                    border: "1px solid #ddd",
                    borderCollapse: "collapse",
                    padding: "3px 6px",
                    textAlign: "right",
                  }}
                >
                  {parseInt(totalData?.[month.month]?.["extensions"] || 0)
                    .toFixed(2)
                    .toLocaleString()}
                </TableCell>
              ))}
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  // backgroundColor: "#FFF8E1",
                  backgroundColor: "lightgreen",
                  color: "black",
                  border: "1px solid #ddd",
                  borderCollapse: "collapse",
                }}
                colSpan={3}
                align="right"
              >
                Total in USD (MRR {totalMrr})
              </TableCell>
              {months.map((month, index) => (
                <TableCell
                  key={index}
                  sx={{
                    // backgroundColor: "#FFF8E1",
                    backgroundColor: "lightgreen",
                    color: "black",
                    border: "1px solid #ddd",
                    borderCollapse: "collapse",
                    padding: "3px 6px",
                    textAlign: "right",
                  }}
                >
                  {parseInt(totalData?.[month.month]?.["total_in_usd"] || 0)
                    .toFixed(2)
                    .toLocaleString()}
                </TableCell>
              ))}
            </TableRow>

            {currencies.map((currency, index) => {
              return (
                <TableRow key={index}>
                  <TableCell
                    sx={{
                      backgroundColor: "#FFF8E1",
                      color: "black",
                      border: "1px solid #ddd",
                      borderCollapse: "collapse",
                    }}
                    colSpan={3}
                    align="right"
                  >
                    {currency} (MRR {currencyMrrs?.[currency]})
                  </TableCell>
                  {months.map((month, index) => (
                    <TableCell
                      key={index}
                      sx={{
                        backgroundColor: "#FFF8E1",
                        color: "black",
                        border: "1px solid #ddd",
                        borderCollapse: "collapse",
                        padding: "3px 6px",
                        textAlign: "right",
                      }}
                    >
                      {parseInt(totalData?.[month.month]?.[currency] || 0)
                        .toFixed(2)
                        .toLocaleString()}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}

            {/* <TableRow>
              <TableCell sx={{ position: 'sticky', bottom: 0, backgroundColor: 'black', color: 'white', border: "1px solid white", borderCollapse: 'separate' }} colSpan={3}>USD</TableCell>
              {months.map((month, index) => (
                <TableCell key={index} sx={{ position: 'sticky', bottom: 0, backgroundColor: 'black', color: 'white', border: "1px solid white", borderCollapse: 'separate' ,textAlign:'right'}}>
                  {"$ " + parseInt(totalData[month]['USD']).toFixed(2).toLocaleString()}
                </TableCell>
              ))}
            </TableRow> */}
          </TableFooter>

          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              count={
                sortedData().filter((record) => {
                  const parts = record[0].split(" ---- ");
                  const companyName = parts[0];
                  const service = parts[1];
                  return (
                    companyName
                      ?.toUpperCase()
                      ?.includes(queryString.toUpperCase()) ||
                    service?.toUpperCase()?.includes(queryString.toUpperCase())
                  );
                }).length
              }
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableRow>
        </Table>
      </TableContainer>
    </Paper>
  );
}
