import React, { useEffect, useState } from "react";
import IssueTransactionTable from "../Components/IssueTransactionTable";
import SnackbarComp from "../Components/SnackbarComp";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import LoaderComp from "../Components/LoaderComp";
import axios from "axios";
import CompanyTable from "../Components/CompanyTable";
import { TextField } from "@mui/material";

const ZOHO = window.ZOHO;

const wholeScreenStyle = {
  backgroundColor: "#ECEFF1",
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
  width: "100%",
};
const parentContainerStyle = {
  width: "96.5%",
  // width: "min(100%, 1152px)",
  margin: "0 auto",
  flexGrow: 1,
  ml: 4,
};
const cusContainerStyle = { backgroundColor: "redd", marginTop: "20px" };
const cusBoxStyle = {
  width: "270px",
  padding: "24px",
  backgroundColor: "#fff",
  borderRadius: "8px",
  boxShadow:
    "0px 1px 3px 0px rgba(0, 0, 0, 0.12), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.20)",
};
const flexStyle = { display: "flex", justifyContent: "start", gap: 2 };
const cusBoxTitleStyle = {
  color: "rgba(0, 0, 0, 0.60)",
  fontSize: "14px",
  fontWeight: 400,
  marginBottom: "8px",
};
const cusBoxAmountStyle = {
  color: "rgba(0, 0, 0, 0.87)",
  fontSize: "24px",
  fontWeight: 600,
};
const flexStyle2 = {
  display: "flex",
  // justifyContent: "space-around",
  gap: "8px",
  backgroundColor: "#fff",
  padding: "16px",
  boxShadow:
    "0px 1px 3px 0px rgba(0, 0, 0, 0.12), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.20)",
  borderRadius: "8px",
};
const cusBox2Style = {
  // flexGrow:1,
  // mx:"4px",
  padding: "16px 24px",
  borderRadius: "6px",
  maxWidth: "33%",
  minWidth: "18%",
};
const cusBox2TitleStyle = {
  color: "rgba(0, 0, 0, 0.87)",
  fontSize: "20px",
  fontWeight: 500,
  marginBottom: "12px",
};
const cusBox2AmountStyle = {
  color: "rgba(0, 0, 0, 0.87)",
  fontSize: "16px",
  fontWeight: 400,
};
const CusContainer = ({ children }) => (
  <div style={cusContainerStyle}>{children}</div>
);
const CusBox = ({ title, value = 0 }) => (
  <Box sx={cusBoxStyle}>
    <Typography variant="h6" style={cusBoxTitleStyle}>
      {title}
    </Typography>
    <Typography variant="body1" style={cusBoxAmountStyle}>
      {value}
    </Typography>
  </Box>
);

const StoreCommissionPreview = ({ storeData, title, bColor, width }) => {
  return (
    <Box sx={{ ...cusBox2Style, ...bColor, width: width }} key={title}>
      <Typography variant="h6" style={cusBox2TitleStyle}>
        {title} Store
      </Typography>
      {Object.keys(storeData).map((entityName) => {
        return (
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="body1" style={cusBox2AmountStyle}>
              {entityName}
            </Typography>
            <Typography variant="body1" style={cusBox2AmountStyle}>
              {storeData?.[entityName]?.currency}{" "}
              {storeData?.[entityName]?.value?.toFixed(2)}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
};

const Dashboard = () => {
  const [zohoLoaded, setZohoLoaded] = useState(false);
  const [pendingCommissionMap, setPendingCommissionMap] = useState({});
  const [allIssueTransactions, setAllIssueTransactions] = useState([]);
  const [allSubscriptions, setAllSubscriptions] = useState([]);
  const [transactionUrl, setTransactionUrl] = useState();
  const [disableFetchDataButton, setDisableFetchDataButton] = useState(false);
  const [renderSnackbar, setRenderSnackbar] = useState(false);
  const [companyData, setCompanyData] = useState({});
  // const [zapiKey, setZapiKey] = useState();
  // const [dataCenterUrl, setDataCenterUrl] = useState();
  const [queryString, setQueryString] = useState("");

  const fetchAllPendingCommissions = async (
    allCommissions = [],
    pageNo = 1
  ) => {
    const pendingCommissionsResp = await ZOHO.CRM.API.searchRecord({
      Entity: "easystoretracker__Store_Commissions",
      Type: "criteria",
      per_page: 200,
      page: pageNo,
      Query: "(easystoretracker__Payout_ID:equals:-1)",
    });
    allCommissions = [
      ...allCommissions,
      ...(pendingCommissionsResp?.data || []),
    ];
    if (pendingCommissionsResp?.info?.more_records === true) {
      return await fetchAllPendingCommissions(allCommissions, pageNo + 1);
    } else {
      return allCommissions;
    }
  };

  const fetchAllIssueTransactions = async (issueTxs = [], pageNo = 1) => {
    const issueTransactionsResp = await ZOHO.CRM.API.searchRecord({
      Entity: "easystoretracker__Store_Transactions",
      Type: "criteria",
      per_page: 200,
      page: pageNo,
      Query: "(easystoretracker__Has_Issues:equals:true)",
    });

    issueTxs = [...issueTxs, ...(issueTransactionsResp?.data || [])];
    if (issueTransactionsResp?.info?.more_records === true) {
      return await fetchAllPendingCommissions(issueTxs, pageNo + 1);
    } else {
      return issueTxs;
    }
  };

  const fetchAllSubscriptions = async (subs = [], pageNo = 1) => {
    const subscriptions = await ZOHO.CRM.API.getAllRecords({
      Entity: "easystoretracker__Store_Subscriptions",
      per_page: 200,
      page: pageNo,
    });

    subs = [...subs, ...(subscriptions?.data || [])];
    if (subscriptions?.info?.more_records === true) {
      return await fetchAllSubscriptions(subs, pageNo + 1);
    } else {
      return subs;
    }
  };

  useEffect(() => {
    ZOHO.embeddedApp.on("PageLoad", async function (data) {
      //Custom Bussiness logic goes here
      const currentEnv = await ZOHO.CRM.CONFIG.GetCurrentEnvironment();
      const dataCenterMap = {
        US: "https://crm.zoho.com",
        EU: "https://crm.zoho.eu",
        AU: "https://crm.zoho.com.au",
        IN: "https://crm.zoho.in",
        China: "https://crm.zoho.com.cn",
        JP: "https://crm.zoho.jp",
      };

      // setDataCenterUrl(
      //   "https://crm.zoho" +
      //     dataCenterMap?.[currentEnv?.deployment].split("https://crm.zoho")?.[1]
      // );

      var func_name = "easystoretracker__company_prediction";
      // var func_name = "test";
      var req_data = {
        arguments: JSON.stringify({}),
      };
      const compData = await ZOHO.CRM.FUNCTIONS.execute(func_name, req_data);

      setCompanyData(compData?.data || {});

      const modulesResp = await ZOHO.CRM.META.getModules();
      const filteredModule = modulesResp?.modules?.filter(
        (indv) => indv.api_name === "easystoretracker__Store_Transactions"
      );

      // await fetchOrgVariablesData();
      const transactionUrlL =
        process.env.REACT_APP_SANDBOX === true
          ? "https://plugin-easystoretracker.zohosandbox.com/crm/tab/" +
            filteredModule?.[0]?.module_name
          : dataCenterMap?.[currentEnv?.deployment] +
            "/crm/tab/" +
            filteredModule?.[0]?.module_name;
      setTransactionUrl(transactionUrlL);

      const pendingCommissions = await fetchAllPendingCommissions();
      let allIssueTxs = await fetchAllIssueTransactions();
      allIssueTxs = allIssueTxs.filter(
        (indv) =>
          indv?.easystoretracker__Store_Subscription &&
          indv?.easystoretracker__Has_Issues
      );
      setAllIssueTransactions({ allIssueTxs });
      let pendingCommissionMapLocal = {};

      for (let indvComm of pendingCommissions) {
        pendingCommissionMapLocal = {
          ...pendingCommissionMapLocal,
          [indvComm.easystoretracker__Store_Location]: {
            ...pendingCommissionMapLocal[
              indvComm.easystoretracker__Store_Location
            ],
            [indvComm.easystoretracker__Entity]: {
              currency: indvComm?.easystoretracker__Commission_Currency,
              value:
                (pendingCommissionMapLocal?.[
                  indvComm.easystoretracker__Store_Location
                ]?.[indvComm.easystoretracker__Entity]?.value || 0) +
                (indvComm?.easystoretracker__Commission_Amount || 0.0) *
                  (indvComm?.easystoretracker__Eligible_Transaction_Amount0 >= 0
                    ? 1
                    : -1),
            },
          },
        };
      }
      setPendingCommissionMap(pendingCommissionMapLocal);
      const allSubs = await fetchAllSubscriptions();
      setAllSubscriptions(allSubs);
      setZohoLoaded(true);
    });
    ZOHO.embeddedApp.init();
  }, []);

  const renderSnackbarFunction = () => {
    setRenderSnackbar(true);
    const timeout = setTimeout(function () {
      setRenderSnackbar(false);
    }, 5000);
  };

  if (zohoLoaded) {
    return (
      <div style={wholeScreenStyle}>
        <div style={parentContainerStyle}>
          <CusContainer>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{ fontSize: "24px", fontWeight: 600 }}>
                Store Tracker
              </Typography>
              <Button
                disabled={disableFetchDataButton ? true : false}
                variant="contained"
                size="small"
                sx={{ textTransform: "capitalize" }}
                onClick={async () => {
                  setDisableFetchDataButton(true);
                  var func_name = "easystoretracker__fetchlatestdata";
                  var req_data = {
                    arguments: JSON.stringify({
                      mailid: "siprxx.xxx@xxxx.com",
                    }),
                  };
                  ZOHO.CRM.FUNCTIONS.execute(func_name, req_data)
                    .then(function (data) {
                      renderSnackbarFunction();
                      setDisableFetchDataButton(false);
                    })
                    .catch((error) => {});
                }}
              >
                {disableFetchDataButton ? (
                  <CircularProgress size={16} sx={{ mr: "8px" }} />
                ) : null}
                Fetch Up-to-date Data
              </Button>
            </Box>
          </CusContainer>

          <CusContainer key="details">
            <Box sx={flexStyle}>
              <CusBox
                title="Active Subscriptions"
                value={
                  allSubscriptions.filter(
                    (indv) => indv?.easystoretracker__Status === "active"
                  ).length
                }
              />
              <CusBox
                title="InActive Subscriptions"
                value={
                  allSubscriptions.filter(
                    (indv) => indv?.easystoretracker__Status === "inactive"
                  ).length
                }
              />
              <CusBox
                title="Issues"
                value={allIssueTransactions?.allIssueTxs?.length || 0}
              />
              <CusBox
                title="Extensions"
                value={
                  allSubscriptions?.filter(
                    (indv) =>
                      indv.easystoretracker__Business_Category ===
                        "extensions" &&
                      indv.easystoretracker__Status === "active"
                  )?.length
                }
              />
              {/* <button
                onClick={async () => {
                  var func_name = "easystoretracker__fetchlatestdata";
                  var req_data = {
                    arguments: JSON.stringify({
                      mailid: "siprxx.xxx@xxxx.com",
                    }),
                  };
                  ZOHO.CRM.FUNCTIONS.execute(func_name, req_data)
                    .then(function (data) {
                      
                    })
                    .catch((error) => {});
                }}
              >
                Fetch Current Data
              </button> */}
            </Box>
          </CusContainer>

          <CusContainer key="Store Block">
            <Box sx={flexStyle2}>
              {Object.keys(pendingCommissionMap).map((storeName, index) => {
                const width = 98 / Object.keys(pendingCommissionMap).length;
                return (
                  <StoreCommissionPreview
                    width={width.toFixed(2) + "%"}
                    bColor={{
                      backgroundColor: index % 2 === 0 ? "#E1F5FE" : "#F1F8E9",
                    }}
                    title={storeName}
                    storeData={pendingCommissionMap?.[storeName]}
                  />
                );
              })}
            </Box>
          </CusContainer>

          <CusContainer key="Income Predictions">
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 0.8,
              }}
            >
              <Typography fontWeight="bold">Income Predictions</Typography>
              <TextField
                size="small"
                label="Search"
                onChange={(e) => setQueryString(e.target.value)}
              />
            </Box>
            <CompanyTable queryString={queryString} companyData={companyData} />
          </CusContainer>

          <CusContainer key="Issues List">
            {allIssueTransactions?.length ?? (
              <IssueTransactionTable
                allIssueTransactions={allIssueTransactions?.allIssueTxs || []}
                setAllIssueTransactions={setAllIssueTransactions}
                transactionUrl={transactionUrl}
              />
            )}
          </CusContainer>
        </div>
        {renderSnackbar ? (
          <SnackbarComp snackbarMessage="Data fetched successfully" />
        ) : null}
      </div>
    );
  }
  return <LoaderComp msg="Please Wait while fetching data" />;
};

export default Dashboard;
