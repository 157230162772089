import React, { useState, useEffect } from "react";
import axios from "axios";
import { Box, Grid, Typography } from "@mui/material";
import { ResponsiveBar } from "@nivo/bar";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { Bar } from "react-chartjs-2";

import Backdrop from "@mui/material/Backdrop";
import SubscriptionHistoryChart from "../Components/SubscriptionHistoryChart";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const ZOHO = window.ZOHO;

const containerStyle = {
  width: "100%",
  height: "450px",
};
export default function RelatedList() {
  const [zohoLoaded, setZohoLoaded] = useState(false);
  const [loading, setLoading] = useState(true);

  const [entity, setEntity] = useState();
  const [entityId, setEntityId] = useState();
  const [apiKey, setApiKey] = useState();
  const [orgId, setOrgId] = useState();
  const [zapiKey, setZapiKey] = useState();
  const [dataCenterUrl, setDataCenterUrl] = useState("");
  const [chartData, setChartData] = useState();
  const [subscription, setSubscription] = useState();
  const [commission, setCommission] = useState();
  const [transaction, setTransaction] = useState();
  const [latestEligablePercentage, setLatestEligablePercentage] = useState(0);
  const [latestCommPercentage, setLatestCommPercentage] = useState(0);
  const [totalTxCount, setTotalTxCount] = useState(0);
  const [totalTxAmount, setTotalTxAmount] = useState(0);
  const [totalCommCount, setTotalCommCount] = useState(0);
  const [totalCommAmount, setTotalCommAmount] = useState(0);
  const [currentTooltipData, setCurrentTooltipData] = useState();

  // const getColor = (bar) => {
  //   const indv = sortedChartDatas[bar.index];
  //   if (
  //     (commission?.id === indv.commId && commission?.id !== undefined) ||
  //     (transaction?.id === indv.txId && transaction?.id !== undefined)
  //   ) {
  //     // setCurrentTooltipData(indv);
  //     return "black";
  //   }
  //   if (bar.id == "txAmount") {
  //     if (indv.txAmount > 0 && indv.commAmount === 0) {
  //       return "red";
  //     }
  //     if (indv.txAmount < 0) {
  //       return "red";
  //     } else {
  //       if (indv.commStatus === "Down") {
  //         return "orange";
  //       } else {
  //         return "green";
  //       }
  //     }
  //   }

  //   return "pink";
  // };

  useEffect(() => {
    setLoading(true);
    async function initZoho() {
      ZOHO.embeddedApp.on("PageLoad", async function (data) {
        
        setEntity(data?.Entity);
        setEntityId(data?.EntityId);
        ZOHO.CRM.UI.Resize({ height: "750", width: "1000" });
        // let subscriptionId = "";
        // if (data?.Entity === "easystoretracker__Store_Commissions") {
        //   const commissionResp = await ZOHO.CRM.API.getRecord({
        //     Entity: data?.Entity,
        //     RecordID: data?.EntityId,
        //   });
        //   setCommission(commissionResp?.data?.[0]);
        //   subscriptionId =
        //     commissionResp?.data?.[0]?.easystoretracker__Store_Subscription?.id;
        // } else if (data?.Entity === "easystoretracker__Store_Transactions") {
        //   const transactionResp = await ZOHO.CRM.API.getRecord({
        //     Entity: data?.Entity,
        //     RecordID: data?.EntityId,
        //   });
        //   setTransaction(transactionResp?.data?.[0]);
        //   subscriptionId =
        //     transactionResp?.data?.[0]?.easystoretracker__Store_Subscription
        //       ?.id;
        // } else {
        //   subscriptionId = data?.EntityId;
        // }
        
        // const subscriptionResp = await ZOHO.CRM.API.getRecord({
        //   Entity: "easystoretracker__Store_Subscriptions",
        //   RecordID: subscriptionId,
        // });
        // setSubscription(subscriptionResp?.data?.[0]);
        // // Get Transactions and Commissions
        // const transactions = await ZOHO.CRM.API.getRelatedRecords({
        //   Entity: "easystoretracker__Store_Subscriptions",
        //   RecordID: subscriptionId,
        //   RelatedList: "easystoretracker__Store_Transactions",
        //   page: 1,
        //   per_page: 200,
        // });
        // let transactionMap = {};
        // let totalNonZeroTx = 0;
        // let totalNonZeroTxAmount = 0;
        // let totalComm = 0;
        // let totalCommAmount = 0;
        // transactions?.data?.forEach((indvTx) => {
        //   if (indvTx?.easystoretracker__Transaction_Amount > 0) {
        //     totalNonZeroTx += 1;
        //     totalNonZeroTxAmount +=
        //       indvTx?.easystoretracker__Transaction_Amount;
        //   }
        //   transactionMap[indvTx.Name] = {
        //     ...transactionMap[indvTx.Name],
        //     txId: indvTx.id,
        //     txAmount: indvTx?.easystoretracker__Transaction_Amount || 0,
        //     txDate: indvTx?.easystoretracker__Transaction_Date_Time,
        //     elegibleAmount: 0,
        //     commAmount: 0,
        //     serviceName: "",
        //     description: indvTx?.easystoretracker__Description,
        //     yearsPassed:
        //       indvTx?.easystoretracker__Months_from_First_Subscription,
        //   };
        // });
        // setTotalTxCount(totalNonZeroTx);
        // setTotalTxAmount(totalNonZeroTxAmount);
        
        // setChartData(transactionMap);
        // const commissions = await ZOHO.CRM.API.getRelatedRecords({
        //   Entity: "easystoretracker__Store_Subscriptions",
        //   RecordID: subscriptionId,
        //   RelatedList: "easystoretracker__Store_Commissions",
        //   page: 1,
        //   per_page: 200,
        // });
        
        // let latestElgPer = 0;
        // let latestCommPer = 0;

        // commissions?.data?.forEach((indvComm) => {
        //   totalComm += 1;
        //   totalCommAmount += indvComm?.easystoretracker__Commission_Amount || 0;
        //   transactionMap[indvComm.easystoretracker__Transaction_ID] = {
        //     ...transactionMap[indvComm.easystoretracker__Transaction_ID],
        //     commId: indvComm.id,
        //     commAmount: indvComm?.easystoretracker__Commission_Amount || 0,
        //     txDate: indvComm?.easystoretracker__Transaction_Date_Time,
        //     elegibleAmount:
        //       indvComm?.easystoretracker__Eligible_Transaction_Amount0 || 0,
        //     serviceName: indvComm?.easystoretracker__Service_Name || "",
        //   };
        //   if (
        //     latestElgPer === 0 &&
        //     indvComm?.easystoretracker__Eligible_Transaction_Amount0 > 0 &&
        //     indvComm?.easystoretracker__Transaction_Description.includes(
        //       "Recurring payment"
        //     )
        //   ) {
        
        //     latestElgPer = (
        //       (indvComm?.easystoretracker__Eligible_Transaction_Amount0 /
        //         (indvComm?.easystoretracker__Transaction_Amount || 1)) *
        //       100
        //     ).toFixed(2);
        //     latestCommPer = (
        //       (indvComm?.easystoretracker__Commission_Amount /
        //         (indvComm?.easystoretracker__Eligible_Transaction_Amount0 ||
        //           1)) *
        //       100
        //     ).toFixed(2);
        //   }
        // });
        // setTotalCommAmount(totalCommAmount);
        // setTotalCommCount(totalComm);
        // setLatestEligablePercentage(latestElgPer);
        // setLatestCommPercentage(latestCommPer);
        // // ZOHO.CRM.UI.Resize({ height: "500", width: "1000" });
        // const orgData = {
        //   apiKeys: [
        //     process.env.REACT_APP_EXTENSION_IDENTIFIER + "__API_KEY",
        //     process.env.REACT_APP_EXTENSION_IDENTIFIER + "__Organization_ID",
        //     process.env.REACT_APP_EXTENSION_IDENTIFIER + "__ZAPI_Key",
        //     process.env.REACT_APP_EXTENSION_IDENTIFIER + "__Data_Center_URL",
        //   ],
        // };
        // const orgVariables = await ZOHO.CRM.API.getOrgVariable(orgData);

        // setDataCenterUrl(
        //   orgVariables?.Success?.Content?.[
        //     process.env.REACT_APP_EXTENSION_IDENTIFIER + "__Data_Center_URL"
        //   ]?.value
        //     ? orgVariables?.Success?.Content?.[
        //         process.env.REACT_APP_EXTENSION_IDENTIFIER + "__Data_Center_URL"
        //       ]?.value != "null"
        //       ? orgVariables?.Success?.Content?.[
        //           process.env.REACT_APP_EXTENSION_IDENTIFIER +
        //             "__Data_Center_URL"
        //         ]?.value
        //       : dataCenterUrl
        //     : dataCenterUrl
        // );
        // setApiKey(
        //   orgVariables?.Success?.Content?.[
        //     process.env.REACT_APP_EXTENSION_IDENTIFIER + "__API_KEY"
        //   ]?.value
        // );
        // setOrgId(
        //   orgVariables?.Success?.Content?.[
        //     process.env.REACT_APP_EXTENSION_IDENTIFIER + "__Organization_ID"
        //   ]?.value
        // );
        // setZapiKey(
        //   orgVariables?.Success?.Content?.[
        //     process.env.REACT_APP_EXTENSION_IDENTIFIER + "__ZAPI_Key"
        //   ]?.value
        // );

        // setZohoLoaded(true);
        // /**
        //  * Business Logic
        //  */
        setLoading(false);
      });
      ZOHO.embeddedApp.init();
    }
    initZoho();
  }, []);

  useEffect(() => {
    if (zohoLoaded) {
      /**
       * Business Logic
       */
    }
  }, [zohoLoaded]);
  if (loading || !entity || !entityId)
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        Loading..
        {/* <CircularProgress color="inherit" /> */}
      </Backdrop>
    );

  return (
    <SubscriptionHistoryChart data={{ Entity: entity, EntityId: entityId }} />
  );
  // let sortedChartDatas0 = Object.values(chartData).sort(function (a, b) {
  //   return new Date(b.txDate) - new Date(a.txDate);
  // });

  // let sortedChartDatas = sortedChartDatas0.filter((indv) => {
  //   if (indv.txAmount !== 0) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // });

  // let latestCommissionDate = "";

  // sortedChartDatas.forEach((indv, index) => {
  //   let currentCommPercentage = 0;
  //   let previousCommPercentage = 0;
  //   if (latestCommissionDate === "" && indv?.commAmount !== 0) {
  //     latestCommissionDate = indv?.txDate;
  //   }
  //   currentCommPercentage = (
  //     ((indv?.commAmount || 0) / (indv?.elegibleAmount || 1)) *
  //     100
  //   ).toFixed(0);
  //   if (index !== sortedChartDatas.length - 1) {
  //     previousCommPercentage = (
  //       ((sortedChartDatas[index + 1]?.commAmount || 0) /
  //         (sortedChartDatas[index + 1]?.elegibleAmount || 1)) *
  //       100
  //     ).toFixed(0);
  //   }
  //   sortedChartDatas[index].currentCommPercentage = currentCommPercentage;
  //   sortedChartDatas[index].previousCommPercentage = previousCommPercentage;
  //   if (currentCommPercentage > previousCommPercentage) {
  //     sortedChartDatas[index].commStatus = "Up";
  //   } else if (currentCommPercentage === previousCommPercentage) {
  //     sortedChartDatas[index].commStatus = "Same";
  //   } else {
  //     sortedChartDatas[index].commStatus = "Down";
  //   }
  // });
  // // sortedChartDatas = sortedChartDatas.slice(
  // //   0,
  // //   sortedChartDatas.length > 30 ? 30 : sortedChartDatas.length
  // // );
  // // setLatestCommDate(latestCommissionDate);

  // // if (entity === "easystoretracker__Store_Subscriptions") {
  // return (
  //   <Box
  //     sx={{
  //       width: "100%",
  //       display: "flex",
  //       bgcolor: "#ffffff",
  //       p: 2,
  //     }}
  //   >
  //     <Box
  //       sx={{
  //         width: "25%",
  //         bgcolor: "rgba(25, 118, 210, 0.04)",
  //         borderRadius: "10px",
  //         p: 2,
  //         mr: 1.5,
  //       }}
  //     >
  //       <Grid container spacing={1}>
  //         {/* <Typography sx={{ fontSize: 14, fontWeight: "bold" }}>
  //           Subscription Details
  //         </Typography> */}
  //         <Grid item xs={6}>
  //           <Box
  //             sx={{
  //               bgcolor: "#F1F8E9",
  //               height: 65,
  //               p: 1,
  //               display: "flex",
  //               flexDirection: "column",
  //               justifyContent: "space-between",
  //             }}
  //           >
  //             <Typography sx={{ fontSize: 12, color: "rgba(0, 0, 0, 0.6)" }}>
  //               Status
  //             </Typography>
  //             <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
  //               {subscription?.easystoretracker__Status}
  //             </Typography>
  //           </Box>
  //         </Grid>
  //         <Grid item xs={6}>
  //           <Box
  //             sx={{
  //               bgcolor: "#FFF8E1",
  //               height: 65,
  //               p: 1,
  //               display: "flex",
  //               flexDirection: "column",
  //               justifyContent: "space-between",
  //             }}
  //           >
  //             <Typography sx={{ fontSize: 12, color: "rgba(0, 0, 0, 0.6)" }}>
  //               Elg %
  //             </Typography>
  //             <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
  //               {latestEligablePercentage}
  //             </Typography>
  //           </Box>
  //         </Grid>
  //         <Grid item xs={6}>
  //           <Box
  //             sx={{
  //               bgcolor: "#E1F5FE",
  //               height: 65,
  //               p: 1,
  //               display: "flex",
  //               flexDirection: "column",
  //               justifyContent: "space-between",
  //             }}
  //           >
  //             <Typography sx={{ fontSize: 12, color: "rgba(0, 0, 0, 0.6)" }}>
  //               Sub Start
  //             </Typography>
  //             <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
  //               {subscription?.easystoretracker__Subscription_Start_Date}
  //             </Typography>
  //           </Box>
  //         </Grid>
  //         <Grid item xs={6}>
  //           <Box
  //             sx={{
  //               bgcolor: "#EDE7F6",
  //               height: 65,
  //               p: 1,
  //               display: "flex",
  //               flexDirection: "column",
  //               justifyContent: "space-between",
  //             }}
  //           >
  //             <Typography sx={{ fontSize: 12, color: "rgba(0, 0, 0, 0.6)" }}>
  //               Latest Comm %
  //             </Typography>
  //             <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
  //               {latestCommPercentage}
  //             </Typography>
  //           </Box>
  //         </Grid>
  //         <Grid item xs={6}>
  //           <Box
  //             sx={{
  //               bgcolor: "#F1F8E9",
  //               height: 65,
  //               p: 1,
  //               display: "flex",
  //               flexDirection: "column",
  //               justifyContent: "space-between",
  //             }}
  //           >
  //             <Typography sx={{ fontSize: 12, color: "rgba(0, 0, 0, 0.6)" }}>
  //               Tx/Comm Count
  //             </Typography>
  //             <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
  //               {totalTxCount + " / " + totalCommCount}
  //             </Typography>
  //           </Box>
  //         </Grid>
  //         <Grid item xs={6}>
  //           <Box
  //             sx={{
  //               bgcolor: "#FFF8E1",
  //               height: 65,
  //               p: 1,
  //               display: "flex",
  //               flexDirection: "column",
  //               justifyContent: "space-between",
  //             }}
  //           >
  //             <Typography sx={{ fontSize: 12, color: "rgba(0, 0, 0, 0.6)" }}>
  //               Lifetime Comm
  //             </Typography>
  //             <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
  //               {totalCommAmount.toFixed(2)}
  //             </Typography>
  //           </Box>
  //         </Grid>
  //         <Grid item xs={6}>
  //           <Box
  //             sx={{
  //               bgcolor: "#E1F5FE",
  //               height: 65,
  //               p: 1,
  //               display: "flex",
  //               flexDirection: "column",
  //               justifyContent: "space-between",
  //             }}
  //           >
  //             <Typography sx={{ fontSize: 12, color: "rgba(0, 0, 0, 0.6)" }}>
  //               Comm Count
  //             </Typography>
  //             <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
  //               {totalCommCount}
  //             </Typography>
  //           </Box>
  //         </Grid>
  //         <Grid item xs={6}>
  //           <Box
  //             sx={{
  //               bgcolor: "#EDE7F6",
  //               height: 65,
  //               p: 1,
  //               display: "flex",
  //               flexDirection: "column",
  //               justifyContent: "space-between",
  //             }}
  //           >
  //             <Typography sx={{ fontSize: 12, color: "rgba(0, 0, 0, 0.6)" }}>
  //               Service
  //             </Typography>
  //             <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
  //               {subscription?.easystoretracker__Service_Name}
  //             </Typography>
  //           </Box>
  //         </Grid>
  //         <Grid item xs={6}>
  //           <Box
  //             sx={{
  //               bgcolor: "#F1F8E9",
  //               height: 65,
  //               p: 1,
  //               display: "flex",
  //               flexDirection: "column",
  //               justifyContent: "space-between",
  //             }}
  //           >
  //             <Typography sx={{ fontSize: 12, color: "rgba(0, 0, 0, 0.6)" }}>
  //               Last Comm
  //             </Typography>
  //             <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
  //               {latestCommissionDate?.split(" ")?.[0] || ""}
  //             </Typography>
  //           </Box>
  //         </Grid>
  //         <Grid item xs={6}>
  //           <Box
  //             sx={{
  //               bgcolor: "#FFF8E1",
  //               height: 65,
  //               p: 1,
  //               display: "flex",
  //               flexDirection: "column",
  //               justifyContent: "space-between",
  //             }}
  //           >
  //             <Typography sx={{ fontSize: 12, color: "rgba(0, 0, 0, 0.6)" }}>
  //               Paid User
  //             </Typography>
  //             <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
  //               {subscription?.easystoretracker__Paid_Users}
  //             </Typography>
  //           </Box>
  //         </Grid>
  //         <Grid item xs={6}>
  //           <Box
  //             sx={{
  //               bgcolor: "#E1F5FE",
  //               height: 65,
  //               p: 1,
  //               display: "flex",
  //               flexDirection: "column",
  //               justifyContent: "space-between",
  //             }}
  //           >
  //             {" "}
  //             <Typography sx={{ fontSize: 12, color: "rgba(0, 0, 0, 0.6)" }}>
  //               Billing Freq
  //             </Typography>
  //             <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
  //               {subscription?.easystoretracker__Billing_Frequency}
  //             </Typography>
  //           </Box>
  //         </Grid>
  //         <Grid item xs={6}>
  //           <Box
  //             sx={{
  //               bgcolor: "#EDE7F6",
  //               height: 65,
  //               p: 1,
  //               display: "flex",
  //               flexDirection: "column",
  //               justifyContent: "space-between",
  //             }}
  //           >
  //             {" "}
  //             <Typography sx={{ fontSize: 12, color: "rgba(0, 0, 0, 0.6)" }}>
  //               Next Rec
  //             </Typography>
  //             <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
  //               {subscription?.easystoretracker__Status === "active"
  //                 ? subscription?.easystoretracker__Next_Recurring_Date
  //                 : ""}
  //             </Typography>
  //           </Box>
  //         </Grid>
  //       </Grid>
  //     </Box>
  //     <Box
  //       sx={{
  //         width: "75%",
  //         bgcolor: "rgba(25, 118, 210, 0.04)",
  //         borderRadius: "10px",
  //         height: 465,
  //         p: 2,
  //         display: "flex",
  //       }}
  //     >
  //       <Box sx={{ width: "85%" }}>
  //         {/* <Bar
  //             options={{
  //               events: ["click"],
  //               backgroundColor: "rgba(25, 118, 210, 0.04)",
  //               responsive: true,
  //               intersection: {
  //                 mode: "nearest",
  //               },
  //               plugins: {
  //                 legend: {
  //                   position: "top",
  //                 },
  //                 title: {
  //                   display: true,
  //                   text: "Subsctiption Overview",
  //                 },
  //                 tooltip: {
  //                   enabled: true,
  //                   external: (context) => {
  //                     const { chart, tooltip } = context;
  
  //                     const barData =
  //                       sortedChartDatas[tooltip?.dataPoints?.[0]?.dataIndex] ||
  //                       {};
  
  //                     setCurrentTooltipData(barData);
  //                   },
  //                 },
  //               },
  //               scales: {
  //                 x: {
  //                   stacked: true,
  //                 },
  //                 y: {
  //                   stacked: true,
  //                 },
  //               },
  //             }}
  //             data={{
  //               labels: sortedChartDatas.map((indv) => {
  //                 if (indv.txAmount !== 0) return indv.txDate;
  //               }),
  //               datasets: [
  //                 {
  //                   label: "Transactions",
  //                   data: sortedChartDatas.map((indv) => {
  //                     if (indv.txAmount !== 0) return indv.txAmount;
  //                   }),
  //                   backgroundColor: sortedChartDatas.map((indv, index) => {
  //                     if (indv.txAmount !== 0 && indv.commAmount === 0) {
  //                       return "red";
  //                     } else {
  //                       if (indv.commStatus === "Down") {
  //                         return "Pink";
  //                       } else {
  //                         return "green";
  //                       }
  //                     }
  //                   }),
  //                 },
  //                 {
  //                   label: "Commissions",
  //                   data: sortedChartDatas.map((indv) => {
  //                     if (indv.txAmount > 0) return indv.commAmount;
  //                   }),
  //                   backgroundColor: sortedChartDatas.map((indv) => {
  //                     return "rgba(53, 162, 235, 0.5)";
  //                   }),
  //                 },
  //               ],
  //             }}
  //           /> */}
  //         <Typography>
  //           Tx and Comm Timeline of{" "}
  //           <strong>
  //             {subscription?.easystoretracker__Customer_Company_Name}
  //           </strong>
  //         </Typography>
  //         <ResponsiveBar
  //           data={sortedChartDatas}
  //           keys={["txAmount", "commAmount"]}
  //           indexBy="txDate"
  //           margin={{ top: 10, right: 0, bottom: 95, left: 0 }}
  //           padding={0.3}
  //           valueScale={{ type: "linear" }}
  //           indexScale={{ type: "band", round: true }}
  //           colors={getColor}
  //           defs={[
  //             {
  //               id: "dots",
  //               type: "patternDots",
  //               background: "inherit",
  //               color: "#38bcb2",
  //               size: 4,
  //               padding: 1,
  //               stagger: true,
  //             },
  //             {
  //               id: "lines",
  //               type: "patternLines",
  //               background: "inherit",
  //               color: "#eed312",
  //               rotation: -45,
  //               lineWidth: 6,
  //               spacing: 10,
  //             },
  //           ]}
  //           fill={[
  //             {
  //               match: {
  //                 id: "txAmount",
  //               },
  //               id: "line",
  //             },
  //             {
  //               match: {
  //                 id: "commAmount",
  //               },
  //               id: "dots",
  //             },
  //           ]}
  //           borderColor={{
  //             from: "color",
  //             modifiers: [["darker", 1.6]],
  //           }}
  //           axisTop={null}
  //           axisRight={null}
  //           axisBottom={{
  //             tickSize: 5,
  //             tickPadding: 5,
  //             tickRotation: -35,
  //             legend: "Transaction Date",
  //             legendPosition: "middle",
  //             legendOffset: 82,
  //           }}
  //           enableLabel={false}
  //           axisLeft={null}
  //           labelSkipWidth={12}
  //           labelSkipHeight={12}
  //           labelTextColor={{
  //             from: "color",
  //             modifiers: [["darker", 1.6]],
  //           }}
  //           onClick={(bar) => {
  //             const indv = sortedChartDatas[bar.index];
  
  //             setCurrentTooltipData(indv);
  //           }}
  //           legends={[
  //             {
  //               dataFrom: "keys",
  //               anchor: "bottom-right",
  //               direction: "column",
  //               justify: false,
  //               translateX: 120,
  //               translateY: 0,
  //               itemsSpacing: 2,
  //               itemWidth: 100,
  //               itemHeight: 20,
  //               itemDirection: "left-to-right",
  //               itemOpacity: 0.85,
  //               symbolSize: 20,
  //               effects: [
  //                 {
  //                   on: "hover",
  //                   style: {
  //                     itemOpacity: 1,
  //                   },
  //                 },
  //               ],
  //             },
  //           ]}
  //           role="application"
  //           ariaLabel="Nivo bar chart demo"
  //           barAriaLabel={(e) =>
  //             e.id + ": " + e.formattedValue + " in country: " + e.indexValue
  //           }
  //         />
  //       </Box>
  //       <Box sx={{ width: "15%", ml: 2 }}>
  //         <Typography sx={{ fontSize: 14, fontWeight: "bold" }}>
  //           Selected Details
  //         </Typography>
  //         <Box
  //           sx={{
  //             bgcolor: "#F1F8E9",
  //             height: 55,
  //             p: 1,
  //           }}
  //         >
  //           <Typography sx={{ fontSize: 12, color: "rgba(0, 0, 0, 0.6)" }}>
  //             Tx Amount
  //           </Typography>
  //           <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
  //             {currentTooltipData?.txAmount || "0"}
  //           </Typography>
  //         </Box>
  //         <Box
  //           sx={{
  //             bgcolor: "#FFF8E1",
  //             height: 55,
  //             p: 1,
  //           }}
  //         >
  //           <Typography sx={{ fontSize: 12, color: "rgba(0, 0, 0, 0.6)" }}>
  //             Eligable Amount
  //           </Typography>
  //           <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
  //             {currentTooltipData?.elegibleAmount}
  //           </Typography>
  //         </Box>

  //         <Box
  //           sx={{
  //             bgcolor: "#E1F5FE",
  //             height: 55,
  //             p: 1,
  //           }}
  //         >
  //           <Typography sx={{ fontSize: 12, color: "rgba(0, 0, 0, 0.6)" }}>
  //             Comm Amnt
  //           </Typography>
  //           <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
  //             {currentTooltipData?.commAmount}
  //           </Typography>
  //         </Box>
  //         <Box
  //           sx={{
  //             bgcolor: "#EDE7F6",
  //             height: 55,
  //             p: 1,
  //           }}
  //         >
  //           <Typography sx={{ fontSize: 12, color: "rgba(0, 0, 0, 0.6)" }}>
  //             Tx Date
  //           </Typography>
  //           <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
  //             {currentTooltipData?.txDate?.split(" ")?.[0] || ""}
  //           </Typography>
  //         </Box>
  //         <Box
  //           sx={{
  //             bgcolor: "#F1F8E9",
  //             height: 55,
  //             p: 1,
  //           }}
  //         >
  //           <Typography sx={{ fontSize: 12, color: "rgba(0, 0, 0, 0.6)" }}>
  //             Comm %
  //           </Typography>
  //           <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
  //             {currentTooltipData?.currentCommPercentage}
  //           </Typography>
  //         </Box>
  //         <Box
  //           sx={{
  //             bgcolor: "#FFF8E1",
  //             height: 55,
  //             p: 1,
  //           }}
  //         >
  //           <Typography sx={{ fontSize: 12, color: "rgba(0, 0, 0, 0.6)" }}>
  //             Years Passed
  //           </Typography>
  //           <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
  //             {currentTooltipData?.yearsPassed} /{" "}
  //             {(
  //               ((currentTooltipData?.elegibleAmount || 0) /
  //                 (currentTooltipData?.txAmount || 1)) *
  //               100
  //             ).toFixed(2)}
  //           </Typography>
  //         </Box>
  //         <Box
  //           sx={{
  //             bgcolor: "#E1F5FE",
  //             height: 85,
  //             p: 1,
  //           }}
  //         >
  //           <Typography sx={{ fontSize: 12, color: "rgba(0, 0, 0, 0.6)" }}>
  //             Description
  //           </Typography>
  //           <Typography
  //             sx={{
  //               fontSize:
  //                 currentTooltipData?.description?.length < 30 ? 14 : 12,
  //               fontWeight: "bold",
  //             }}
  //           >
  //             {currentTooltipData?.description}
  //           </Typography>
  //         </Box>
  //       </Box>
  //     </Box>
  //   </Box>
  // );
  // }
  // return <>UI for Related List</>;
}
