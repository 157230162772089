import { TableCell } from "@mui/material";
import React from "react";

const ExtraCell = ({ width }) => {
  return (
    <TableCell
      sx={{ border: "1px solid #ddd", height: 35, width: `${width}%` }}
    />
  );
};

export default ExtraCell;
